<template>
<div class="tableList account-deep">
  <div class="table table-time">
    <el-table :data="purchaseData" stripe style="width: 100%" border>
      <el-table-column prop="purchaseOrderNumber" label="采购单编号" :show-overflow-tooltip="true" min-width="120px"></el-table-column>
      <el-table-column prop="purchaseLineNumber" label="采购单行号" :show-overflow-tooltip="true" min-width="100px"></el-table-column>
      <el-table-column prop="batchLine" label="分批行" :show-overflow-tooltip="true" min-width="85px"></el-table-column>
      <el-table-column prop="itemNumber" label="物料编号" :show-overflow-tooltip="true" min-width="100px"></el-table-column>
      <el-table-column prop="materialDescription" label="物料描述" :show-overflow-tooltip="true" min-width="200px"></el-table-column>
      <el-table-column prop="supplierName" label="供应商名称" :show-overflow-tooltip="true" min-width="180px"></el-table-column>
      <el-table-column prop="orderQuantity" label="订单数量" :show-overflow-tooltip="true" min-width="90px"></el-table-column>
      <el-table-column prop="deliveredQuantity" label="已交数量" :show-overflow-tooltip="true" min-width="90px"></el-table-column>
      <el-table-column prop="zjhdsl" label="已做交货单数量" :show-overflow-tooltip="true" min-width="120px"></el-table-column>
      <el-table-column prop="lgort" label="BU" :show-overflow-tooltip="true" min-width="90px"></el-table-column>
      <el-table-column prop="outstandingQuantity" label="未交数量" :show-overflow-tooltip="true" min-width="90px"></el-table-column>
      <el-table-column prop="deliveryQuantity" label="交付数量" :show-overflow-tooltip="true" min-width="90px"></el-table-column>
      <el-table-column prop="bednr" label="需求类型" :show-overflow-tooltip="true" min-width="90px"></el-table-column>
      <el-table-column prop="demandDate" label="需求日期" :show-overflow-tooltip="true" min-width="110px"></el-table-column>
      <el-table-column prop="LTDate" label="LT日期" :show-overflow-tooltip="true" min-width="110px"></el-table-column>
      <el-table-column prop="newDeliveryDate" label="计划交付日期" :show-overflow-tooltip="true" min-width="120">
        <template #default="scope">
          {{scope.row.newDeliveryDate||scope.row.actualDeliveryDate}}
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="创建时间" :show-overflow-tooltip="true" min-width="170px"></el-table-column>
      <el-table-column prop="modifyDate" label="最后更新日期" :show-overflow-tooltip="true" min-width="170px"></el-table-column>
      <el-table-column label="操作" fixed="right" min-width="100">
        <template #default="scope">
          <el-button size="mini" type="text" @click="handEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="text" @click="logBtn(scope.$index, scope.row)">日志</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="queryDiaBox">
    <dialog-box ref="queryDialog" customClass="deliveryDialogst queryDiaBoxList"  :append-to-body="true" :lock-scroll="false" dialogWidth="80%"
    :dialogShow="dialogShow" :selectTabData="selectTabData"  @handleClose='handleClose' title="编辑交付计划" componentName="PurchaseBody"  @action="action"
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' }, {label:'确认',action:'action', type:'primary', size:'mini'}]"
      ></dialog-box>
        <dialog-box  :splitDeliverLogData="splitDeliverLogData" :dialogShow="logdialogShow"  @handleClose='handleClose' title="日志" componentName="logdialog" dialogWidth="60%"
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' }, {label:'确认',action:'handleClose', type:'primary', size:'mini'}]"
        ></dialog-box>
  </div>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request, requestForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  props: ['tableData'],
  name: 'PurchasePlanTable',
  data () {
    return {
      dialogShow: false,
      logdialogShow: false,
      purchaseData: [],
      selectTabData: [],
      coptselectTabData: [],
      subSelTableData: [],
      splitDeliverLogData: []
    }
  },
  created () {
    this.purchaseData = this.tableData
    this.judgeBtn()
  },
  methods: {
    // 点击编辑
    handEdit (index, row) {
      // this.selectTabData = row
      request('/api/deliver/deliver/queryParent?id=' + row.id, 'POST').then((res) => {
        if (res.code === '200') {
          this.selectTabData = res.data
          this.coptselectTabData = [...res.data]
          this.selectTabData.forEach(item => {
            item.start = 1
          })
        }
      })
      this.dialogShow = true
      this.$nextTick(() => {
        document.body.querySelector('.deliveryDialogst').parentElement.style.display = 'flex'
        document.body.querySelector('.deliveryDialogst').parentElement.style.alignItems = 'center'
        document.body.querySelector('.deliveryDialogst').parentElement.style.position = 'absolute'
        document.body.querySelector('.deliveryDialogst').parentElement.style.width = '100%'
      })
    },
    logBtn (index, row) {
      this.logdialogShow = true
      console.log('111', row)
      var obj = {
        purchaseOrderNumber: row.purchaseOrderNumber,
        purchaseLineNumber: row.purchaseLineNumber,
        batchLine: row.batchLine.split('-')[0],
        splitBatch: row.splitBatch
      }
      requestForm('/api/deliver/deliver/splitDeliverLog', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.splitDeliverLogData = res.data
          console.log('111222', this.splitDeliverLogData)
        }
      })
    },
    // 点击编辑弹框里的确认
    async action () {
      this.subSelTableData = this.$refs.queryDialog.$refs.content.newSelectTabData
      var newTime = ''
      var newNum = 0
      var timeShow = true
      let mustbzs = true
      this.subSelTableData.forEach(item => {
        if (item.newDeliveryDate === null && item.actualDeliveryDate === null) {
          timeShow = false
        }
        /* if (item.mustbz && (!item.updateDeliveryTimeReason || item.updateDeliveryTimeReason === '')) {
          mustbzs = false
        } */
        if ((item.updateDeliveryTimeReason ?? '').trim() === '') {
          mustbzs = false
        }
      })
      if (!timeShow) {
        this.$message({
          showClose: true,
          message: '请选择交付日期',
          type: 'warning'
        })
        return false
      }
      // if (!mustbzs) {
      //   this.$message({
      //     showClose: true,
      //     message: '请输入交期修改原因',
      //     type: 'warning'
      //   })
      //   return false
      // }
      this.subSelTableData.forEach(item => {
        if (item.newDeliveryDate) {
          newTime = dayjs(item.newDeliveryDate).format('YYYY-MM-DD HH:mm:ss')
          item.newDeliveryDate = newTime
        }
        newNum += parseInt(item.deliveryQuantity)
      })
      const obj = [
        ...this.subSelTableData
      ]
      const deletedata = []// asdfasdf
      for (const row of this.coptselectTabData) {
        let havedata = false
        for (const row1 of obj) {
          if (row1.id === row.id) {
            havedata = true
          }
        }
        if (!havedata) {
          deletedata.push(row.id)
        }
      }

      if (newNum === this.subSelTableData[0].outstandingQuantity) {
        if (deletedata.length > 0) {
          await request('/api/deliver/deliver/delDeliver', 'delete', deletedata).then((res) => {
          })
        }
        request('/api/deliver/deliver/splitDeliver', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$parent.$parent.myBidData()
            this.$message({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            this.dialogShow = false
          }
        })
        this.dialogShow = false
      } else {
        this.$message({
          showClose: true,
          message: '交付数量之和须等于未交付数量',
          type: 'warning'
        })
      }
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
      this.logdialogShow = false
    },
    // 判断按钮显示
    judgeBtn () {
      this.purchaseData.forEach((index, item) => {
        index.start = 1
        index.btnShow = 'show'
      })
    }
  },
  watch: {
    tableData: {
      handler (newData, oldData) {
        this.purchaseData = this.tableData
        this.judgeBtn()
      }
    }
  }
}
</script>
<style>
  .el-overlay{
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .queryDiaBoxList .el-dialog__body{
    padding: 20px;
    overflow: auto;
    background: #FBFCFD;
    max-height: calc(100vh - 232px);
  }
  .el-overlay .queryDiaBoxList {
    max-height: calc(100vh - 60px);
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .queryDiaBoxList .el-dialog__header{
    border-bottom: 1px solid #eaeaea;
    padding: 20px;
  }
  .queryDiaBoxList .el-dialog__footer{
    padding: 20px;
    border-top: 1px solid #eaeaea;
  }
  .table-time .el-table__header-wrapper .el-table__header th{
    background-color: #ededed;
  }
</style>
<style scoped lang="scss">
  @import '@/assets/css/tableList.scss';
  .table-time{
    :deep(.el-table__header-wrapper .el-table__header tr){
      background-color: #ededed;
    }
    :deep(.el-table__header-wrapper .el-table__header th){
      background-color: #ededed;
    }
  }
  .tablepage{
    position: inherit;
  }
  .account-deep{
    .dig-cont{
      padding: 20px;
    }
  }
  .sysUserRoles{
    padding-right: 10px ;
  }
  .tableList{
    margin-top: 20px;
  }
  .table-time :deep(.el-date-editor.el-input){
    width: 100%;
  }
  .table-time :deep(.el-table th:last-child){
    background: #ededed;
    line-height: 32px;
  }
</style>
